
import {computed, defineComponent, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {Component, Material} from "@/domain/model/material/material.model";
import {
  IonBackButton, IonButton, IonButtons,
  IonCard, IonCardContent, IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle, IonToolbar, loadingController, onIonViewWillEnter,
} from "@ionic/vue";
import DetailsProductionItemComponentsComponent
  from "@/app/view/production/details/DetailsProductionItemComponentsComponent.vue";
import DetailsProductionRelatedOrders from "@/app/view/production/details/DetailsProductionRelatedOrders.vue";
import _ from "lodash";
import {Order} from "@/domain/model/order/order.model";
import {useMaterialApi} from "@/domain/service/api/material.api";
import ProductCreateItemComponent from "@/app/view/production/create/ProductCreateItemComponent.vue";
import Autocomplete from "@/app/component/autocomplete/Autocomplete.vue";
import {emptyComponent, newComponent} from "@/domain/command/material/material.command";
import {closeCircleOutline} from "ionicons/icons";
import DetailsProductionAddComponentItemComponent
  from "@/app/view/production/details/DetailsProductionAddComponentItemComponent.vue";
import router from "@/app/router";

export default defineComponent({
  name: "DetailsProductionItemPage",
  components: {
    DetailsProductionAddComponentItemComponent,
    IonPage,
    IonHeader,
    IonContent,
    IonGrid,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonInput,
    IonButton,
    DetailsProductionItemComponentsComponent,
    DetailsProductionRelatedOrders
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const {updateMaterialScrapRatio, update} = useMaterialApi();
    const isNewComponentsLongerThanZero = ref<boolean>(false);
    const id = ref(route.params.id);
    const isNewComponentsDisabled = ref<boolean>(true);
    const newComponents = ref<Component[]>([]);
    // const product = computed<Material>(() => store.getters.materialById(id.value));
    const product = computed<Material>(() => {
      const material: Material = _.cloneDeep(store.getters.materialById(id.value));
      material.components = _.sortBy(material.components, ['ref'], ['asc']);
      return material;
    });

    const scrapPercent = ref<number>(product.value.scrapRatio * 100);
    const ordersByMaterial = computed<Order[]>(() => store.getters.ordersByMaterial(product.value));
    const ordersByKit = computed<Order[]>(() => store.getters.activeOrdersByKit(product.value));
    const isScrapChangesDisabled = ref<boolean>(true);
    const saveScrap = async () => {
      const newKit = _.cloneDeep(product.value);
      newKit.scrapRatio = Math.min(1, scrapPercent.value / 100);
      if (_.isNaN(newKit.scrapRatio))
        newKit.scrapRatio = 0;

      const loading = await loadingController
          .create({
            message: 'Guardando cambios',
            duration: 10000,
          });

      await loading.present();
      await updateMaterialScrapRatio(newKit);
      scrapPercent.value = newKit.scrapRatio * 100;
      isScrapChangesDisabled.value = true;
      await loading.dismiss();
    };

    const addEmptyItem = () => {

      newComponents.value.push({
        ref: '', amount: 0
      });
      if(newComponents.value.length > 0){
        isNewComponentsLongerThanZero.value = true;
      }
    };
    const onNextItem = () => {
      addEmptyItem();
    }

    const onDeleteItem = (index: number) => {
      if (index > -1)
        newComponents.value.splice(index, 1);
      if(newComponents.value.length === 0)
        isNewComponentsLongerThanZero.value = false;
    }

    const onSaveNewComponents = async () => {
      const loading = await loadingController
          .create({
            message: 'Guardando cambios',
            duration: 10000,
          });

      await loading.present();
      const newProduct = _.cloneDeep(product.value);
      const allMaterialsRef: string = store.getters.allMaterial.map((materialNode: Material) => materialNode.ref);
      newComponents.value.forEach((item: Component) => {
        if(!_.includes(allMaterialsRef, item.ref))
          return;
        newProduct.components.push({...item});
      })

      isNewComponentsDisabled.value = true;
      await update(newProduct);
      newComponents.value = [];
      isNewComponentsLongerThanZero.value = false;
      await loading.dismiss();
    }


    onIonViewWillEnter(() => {
      if (id.value === route.params.id)
        return;
      id.value = route.params.id;


      useMaterialApi().fetchById(product.value.id);
    });

    const onSaveButton = () => {
      isNewComponentsDisabled.value = false;
    }
    watch(scrapPercent, () => {
      isScrapChangesDisabled.value = false;
    });


    return {
      onSaveButton,
      addEmptyItem,
      onNextItem,
      onDeleteItem,
      onSaveNewComponents,
      closeCircleOutline,
      product,
      ordersByMaterial,
      ordersByKit,
      scrapRatio: scrapPercent,
      isScrapChangesDisabled,
      saveScrap,
      newComponents,
      isNewComponentsDisabled,
      isNewComponentsLongerThanZero,
    };
  }
})
