
import {computed, defineComponent, ref, watch} from "vue";
import Autocomplete from "@/app/component/autocomplete/Autocomplete.vue";
import {
  IonCol,
  IonIcon, IonInput, IonItem, IonRow,
} from "@ionic/vue";
import {Component, Material} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
import {closeCircleOutline} from "ionicons/icons";

export default defineComponent({
  name: "DetailsProductionAddComponentItemComponent",
  components: {
    Autocomplete,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonIcon,
  },
  props: {
    component: null,
    index: null,
  },
  setup(prop: any, {emit}) {
    const store = useStore();
    const newComponent = ref<Component>(prop.component);
    const indexItem = ref<number>(prop.index);
    const allMaterials = computed<string[]>(() => store.getters.allMaterial.map((materialNode: Material) => materialNode.ref));
    const reference = ref<string>('');
    const placeholder = ref<string>('Busca tu material...')

    const onSelectMaterial = (materialRef: string) => {
      newComponent.value.ref = materialRef;
      reference.value = materialRef;
    }
    const getMaterial = (ref: string) => store.getters.materialByRef(ref);

    const onNextItem = () => {
      emit('on-next-item', 'test');
    };

    const onDeleteItem = () => {
      emit('on-delete-item', indexItem)
    };
    watch(reference, () => {
      emit('on-save-button', 'test');
    })
    return {
      onNextItem,
      onDeleteItem,
      closeCircleOutline,
      newComponent,
      allMaterials,
      placeholder,
      reference,
      onSelectMaterial,
      getMaterial,
    };


  }
})
