
import {defineComponent, ref} from "vue";
import {IonCol, IonItem, IonLabel} from "@ionic/vue";
import {Order} from "@/domain/model/order/order.model";
import {formatDate} from "@/domain/helper/date.helper";


export default defineComponent({
  name: "DetailsProductionRelatedOrders",
  components: {
    IonCol,
    IonItem,
    IonLabel,
  },
  props: {
    order: null,
  },
  setup(prop: any) {
    const orderProp = ref<Order>(prop.order);
    const endDate = formatDate(orderProp.value.deliveryDate, "DD-MM-YYYY");

    return {
      endDate,
      orderProp,
    };
  }
})
