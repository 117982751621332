
import {computed, defineComponent, ref, watch} from "vue";
import {Component, Material} from "@/domain/model/material/material.model";
import {IonBadge, IonButton, IonCol, IonIcon, IonInput, IonItem, IonLabel, loadingController} from "@ionic/vue";
import {useStore} from "vuex";
import {useStock} from "@/domain/service/model/stock.service";
import router from "@/app/router";
import {CreateEntryDelivery} from "@/domain/command/delivery/delivery.command";
import {useMaterialApi} from "@/domain/service/api/material.api";
import _ from "lodash";
import {closeCircleOutline} from 'ionicons/icons';
import {loading} from '@/app/service/ionic/loading';

export default defineComponent({
  name: "DetailsProductionItemComponentsComponent",
  components: {
    IonCol,
    IonItem,
    IonLabel,
    IonBadge,
    IonButton,
    IonInput,
    IonIcon,
  },
  props: {
    productComponent: null,
    product: null,
  },
  setup(prop: any) {
    const component = ref<Component>(prop.productComponent);
    const product = ref<Material>(prop.product);
    const store = useStore();
    const {calculateAvailableStock} = useStock();
    const {updateMaterialScrapRatio, update} = useMaterialApi();
    const isSaveChangesDisabled = ref<boolean>(true);
    const amount = ref<number>(component.value.amount);
    const materialAvailability = computed<number>(() => {
      return calculateAvailableStock(store.getters.allActiveOrders, component.value.ref);
    });
    const lackOfStock = computed<boolean>(() => materialAvailability.value < 0);
    const goMaterialDetails = (ref: string) => {
      const material: Material = store.getters.materialByRef(ref);
      router.push({
        name: 'MaterialDetail',
        params: {id: material.id}
      });
    };
    const deleteComponent = async () => {
      await loading().exec('Eliminando componente...', async () => {
        const materialUpdated: Material = store.getters.materialByRef(product.value.ref);
        const newMaterialToUpdate: Material = _.cloneDeep(materialUpdated);
        newMaterialToUpdate.components = materialUpdated.components.filter((componentNode: Component) => componentNode.ref !== component.value.ref);
        await update(newMaterialToUpdate);
      });
    }
    const saveComponentChanges = async () => {
      const loading = await loadingController
          .create({
            message: 'Guardando cambios',
            duration: 10000,
          });

      await loading.present();
      component.value.amount = amount.value;
      isSaveChangesDisabled.value = true;
      const materialUpdated: Material = store.getters.materialByRef(product.value.ref);
      const newMaterialToUpdate: Material = _.cloneDeep(materialUpdated);
      newMaterialToUpdate.components = materialUpdated.components.map((componentNode: Component) => {
        if(componentNode.ref === component.value.ref)
          return component.value;
        else
          return componentNode;
      });
      await update(newMaterialToUpdate);
      await loading.dismiss();
    };

    watch(amount, () => {
      isSaveChangesDisabled.value = false;
    })

    return {
      closeCircleOutline,
      saveComponentChanges,
      isSaveChangesDisabled,
      component,
      lackOfStock,
      goMaterialDetails,
      amount,
      deleteComponent,
    };
  }
})
