import { loadingController } from '@ionic/vue';

let loadingCtrl: HTMLIonLoadingElement | null = null;

const show = async (message?: string) => {
    loadingCtrl = await loadingController.create({
        message,
    });

    return loadingCtrl.present();
};

const hide = async () => {
    if (loadingCtrl) return loadingCtrl.dismiss();
};

const exec = async (
    showMessage: string,
    callback: () => Promise<any>,
    failCallback?: (e: any) => Promise<any>
): Promise<any> => {
    try {
        await show(showMessage);
        return callback();
    } catch (e) {
        if (failCallback)
        return failCallback(e);
    } finally {
        await hide();
    }
};
const display = (showLoader: boolean, message?: string) => {
    if (showLoader) show(message);
    else hide();
};

export function loading() {
    return { show, hide, exec, display };
}
